form.bioForm {
  position: relative;
  left: 50px;
  top: 70px;
  margin-bottom: 200px;
}

form.bioFormStandalone {
  top: 50px;
}

@media only screen and (max-width: 999.95px) {
  form.bioForm {
    left: 36px;
  }
}

@media only screen and (max-width: 419.95px) {

  form.bioForm {
    left: 27px;
  }
}


@media only screen and (max-width: 384.95px) {
  form.bioForm {
    left: 16px;
  }
}