.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button.MuiPickersDay-day {
  margin-right: 37.5px;
  margin-bottom: 8px;
  position: relative;
  left: 16px;
}

div.dayLightMode button p {
  color: black;
}

div.dayDarkMode button p {
  color: white;
}

div.dayDarkModeCurrentDay button {
  border: 1px solid white;
}

div.dayLightModeCurrentDay button {
  border: 1px solid white;
}

span.MuiPickersCalendarHeader-dayLabel {
  margin-right: 58px;
  position: relative;
  left: 21px;
  color: white;
  z-index: 9999;
}

div.MuiPickersCalendarHeader-daysHeader {
  justify-content: initial;
  width: 510px;
}

.MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
  left: -5px;
  z-index: 999;
  color: white;
  border: none;
  text-align: left;
}

div.MuiPickersCalendarHeader-transitionContainer {
  overflow: visible;
  right: 20px;
}

button.MuiPickersDay-daySelected {
  background-color: transparent;
  border: none;
}

button.MuiPickersDay-daySelected:hover {
  background-color: #141414;
}

.headerLightMode {
  color: black !important;
}

.headerDarkMode {
  color: white !important;
}

.monthDarkMode {
  color: white !important;
}

.monthLightMode {
  color: black !important;
}

div.MuiPickersToolbar-toolbar {
  display: none !important;
}

div.MuiPickersStaticWrapper-staticWrapperRoot {
  background-color: transparent;
}

div.MuiPickersBasePicker-pickerView {
  height: 500px;
  width: 96%;
  display: inline-block;
  position: absolute;
  top: 10px;
  max-width: none;
  left:  10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 999.95px) {
  div.MuiPickersBasePicker-pickerView {
    width: 103%;
    left: -5px;
  }

  .MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
    left: -10px;
    width: 240px;
  }

  div.MuiPickersCalendarHeader-daysHeader {
    justify-content: initial;
    width: 310px;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    margin-right: 38px;
    left: 10px;
  }

  button.MuiPickersDay-day {
    margin-right: 17.5px;
    margin-bottom: 8px;
    position: relative;
    left: 2px;
  }

  div.MuiPickersCalendarHeader-transitionContainer {
    right: 60px;
    width: 220px;
  }

}

@media only screen and (max-width: 729.95px) {

  div.MuiPickersBasePicker-pickerView {
    top: -40px;
    left: -1px;
  }

  .MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
    left: -17px;
  }

  div.MuiPickersCalendarHeader-daysHeader {
    justify-content: initial;
    width: 410px;
    position: relative;
    left: 26px;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    margin-right: 52px;
    left: -10px;
  }

  button.MuiPickersDay-day {
    margin-right: 31px;
    margin-bottom: 8px;
    position: relative;
    left: -18px;
  }

  div.MuiPickersCalendarHeader-transitionContainer {
    right: 98px;
    width: 220px;
  }

  div.MuiPickersCalendar-week {
    width: 500px;
    justify-content: left;
    position: relative;
    left: 25px;
  }
}

@media only screen and (max-width: 529.95px) {

  div.MuiPickersBasePicker-pickerView {
    top: -35px;
  }


  .MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
    left: 32px;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    margin-right: 15px;
    position: relative;
    left: -20px;
  }

  button.MuiPickersDay-day {
    margin-right: 15px;
    margin-bottom: 8px;
    left: -17px;
  }
}

@media only screen and (max-width: 419.95px) {

  .MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
    left: 42px;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    left: -23px;
    margin-right: 12.5px;
  }

  button.MuiPickersDay-day {
    left: -23px;
    margin-right: 13px;
  }
}

@media only screen and (max-width: 384.95px) {
  .MuiPickersSlideTransition-transitionContainer p.MuiTypography-body1 {
    left: 64px;
  }

  div.MuiPickersBasePicker-pickerView {
    width: 102%;
    min-width: 290px;
  }

  span.MuiPickersCalendarHeader-dayLabel {
    margin-right: 4px;
  }

  button.MuiPickersDay-day {
    left: -23px;
    margin-right: 4px;
  }
}
