div.calendarHolder {
  height: 305px;
  width: 96%;
  display: inline-block;
  position: absolute;
  top: 90px;
  left:  10px;
}

div.timesHolder {
  min-height: 60px;
  width: 320px;
  display: inline-block;
  position: relative;
  top: 80px;
  margin-bottom: 50px;
}

div.currentDayHolder button {
  border: 1px solid white;
  color: white;
}

div.currentDayHolderLightMode button {
  border: 1px solid black;
}

div.timezoneWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  top: 40px;
  width: 100%;
  min-height: 30px;
}

div.bookingDayHolder button span p{
  color: #00B6FF !important;
}

div.bookingDayHolder button {
  border: 1px solid #00B6FF;
}

div.bookingDayHolderCurrentDayDark button span p {
  color: white;
}

div.bookingDayHolderCurrentDayLight button span p{
  color: black;
}

div.bookingDayHolderSelected button span p{
  color: white !important;
}

@media only screen and (max-width: 999.95px) {
  div.timesHolder {
    width: 240px;
  }

  div.calendarHolder {
    width: 91%;
    left: 25px;
  }
}

@media only screen and (max-width: 729.95px) {
  div.timesHolder {
    width: 87%;
    left: 33px;
    height: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    column-gap: 3px;
  }

  div.calendarHolder {
    width: 94%;
    top: 90px;
    position: relative;
    left: 0px;
  }
}

@media only screen and (max-width: 529.95px) {

  div.calendarHolder {
    top: 70px;
    height: 340px;
  }

  div.timesHolder {
    left: 30px;
    top: 93px;
    width: 85%;
    column-gap: 5px;
  }
}

@media only screen and (max-width: 419.95px) {
  div.calendarHolder {

  }

  div.timesHolder {
    width: 87%;
    left: 25px;
  }
}

@media only screen and (max-width: 384.95px) {
  div.calendarHolder {
    width: 98%;
  }

  div.timesHolder {
    width: 89%;
    left: 17px;
  }
}
