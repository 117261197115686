div.registrationMainHolder {
  width: 960px;
  display: inline-block;
  
}

div.registrationHolder {
  position: relative;
  display: inline-block;
  width: 960px;
  margin-bottom: 180px;
  height: 819px;
}

img.copyIcon {
  position: relative;
  margin-left: 15px;
  top: 5px;
}

img.copyIcon:hover{
  cursor: pointer;
}

div.linkHolder {
  display: inline-block;
  width: 500px;
  position: absolute;
  left: 45px;
  top: 335px;
  text-align: left;
}

@media only screen and (max-width: 999.95px) {
  div.registrationMainHolder {
    width: 700px;
  }
}

@media only screen and (max-width: 729.95px) {
  div.registrationMainHolder {
    width: 500px;
  }

  div.linkHolder {
    width: 500px;
    left: 30px;
    top: 285px;
  }

  img.copyIcon {
    position: absolute;
    right: 52px;
    top: 0px;
  }
}

@media only screen and (max-width: 529.95px) {
  div.registrationMainHolder {
    width: 400px;
  }

  img.copyIcon {
    right: 158px;
  }
}

@media only screen and (max-width: 419.95px) {
  div.registrationMainHolder {
    width: 370px;
  }

  img.copyIcon {
    right: 183px;
  }
}

@media only screen and (max-width: 384.95px) {
  div.registrationMainHolder {
    width: 300px;
  }

  img.copyIcon {
    right: 252px;
  }
}
